export const getPosition = (csatForm, id) => {
  let position = 1;
  csatForm.FormFields.find((field, index) => {
    if (field.Id === id) {
      position = index + 1;
      return true;
    }
    return false;
  });
  return position;
};

export const isFormMandatory = (csatForm) => {
  if (csatForm && csatForm.FormFields) {
    return csatForm.FormFields.some((field) => field.IsMandatory);
  }
  return false;
};

export const isOnlySingleField = (csatForm) => {
  return csatForm && csatForm.FormFields && csatForm.FormFields.length === 1;
};

/* eslint-disable no-param-reassign */
import { TwirpClient } from 'verloop/ts/twirp';
import {
  GetCSATFormRequest,
  SubmitCSATFormRequest,
  UpsertCSATRequest,
} from 'verloop/ts/services/user_feedback_pb_vrlp';
import { UserFeedback } from 'verloop/ts/gateway/user_feedback_pb_twirp';
import { GatewayAnalytics } from 'verloop/ts/gateway/analytics_pb_twirp';
import { GetUserDataRequest } from 'verloop-livechat/ts/livechat/main/livechat_pb_vrlp';
import { Livechat } from 'verloop-livechat/ts/livechat/main/livechat_pb_twirp';
import { Screen, TrackEventRequest } from 'verloop/ts/gateway/analytics_pb';
import { v4 as uuid } from 'uuid';
import catchError from './helper';

export const IDENTITY_KEY = 'anonymousIdentity';

let Gateway = new TwirpClient('/gateway', false);

// SAMPLE_URL:- wl.verloop.io/api/csat?id=ss566ff565

export const getVisitorToken = (id) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  const raw = JSON.stringify({
    Id: id,
  });
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  return fetch(
    `${process.env.PREACT_CSAT_DEV_KVST_API_HOST}/api/v1/KVStore/Get`,
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data.Data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

const getGatewayOptions = (visitorToken, clientId) => {
  const url = `https://${clientId}.${process.env.PREACT_BASE_URL}/gateway`;
  Gateway = new TwirpClient(url, false);
  Gateway.setOptionsHook((options) => {
    if (visitorToken) {
      options.headers['Visitor-Token'] = visitorToken;
    }
    return options;
  });
};

export const fetchSettings = async (token, clientId) => {
  try {
    getGatewayOptions(token, clientId);
    const req = new GetUserDataRequest();
    const LivechatGateway = new Livechat(Gateway);
    const response = await LivechatGateway.GetUserData(req);
    const data = response.toJSON();
    const settingsMap = data.Settings;
    if (settingsMap && settingsMap.color) {
      return { color: settingsMap.color.Value };
    }
    return { color: '' };
  } catch (err) {
    return { color: '' };
  }
};

export const GetCSATForm = async (roomId, visitorToken, clientId) => {
  try {
    getGatewayOptions(visitorToken, clientId);
    const getCSATFormRequest = new GetCSATFormRequest();
    getCSATFormRequest.setRoomid(roomId);
    const Feedbackgateway = new UserFeedback(Gateway);
    const response = await Feedbackgateway.GetCSATForm(getCSATFormRequest);
    const data = response.toJSON();
    return data.CSATForm;
  } catch (error) {
    return catchError(error);
  }
};

export const SubmitCSATForm = async (clientId, roomId, visitorToken, data) => {
  try {
    getGatewayOptions(visitorToken, clientId);
    const submitCSATFormRequest = new SubmitCSATFormRequest();
    const variableMap = submitCSATFormRequest.getVariablesMap();
    submitCSATFormRequest.setRoomid(roomId);
    submitCSATFormRequest.setScore(data.Score || 0);
    submitCSATFormRequest.setComment(data.Comment || '');
    Object.keys(data.Variables).forEach((value) => {
      if (value && data.Variables[value]) {
        variableMap.set(value, `${data.Variables[value]}`);
      }
    });
    const Feedbackgateway = new UserFeedback(Gateway);

    const response = await Feedbackgateway.SubmitCSATForm(
      submitCSATFormRequest
    );
    return response;
  } catch (error) {
    return catchError(error);
  }
};

export const UpsertCSATForm = async (
  clientId,
  roomId,
  visitorToken,
  data,
  isCsatScoreChanged
) => {
  try {
    getGatewayOptions(visitorToken, clientId);
    const upsertCSATFormRequest = new UpsertCSATRequest();
    const variableMap = upsertCSATFormRequest.getVariablesMap();
    upsertCSATFormRequest.setRoomid(roomId);
    upsertCSATFormRequest.setScore(data.Score || 0);
    upsertCSATFormRequest.setComment(data.Comment || '');
    upsertCSATFormRequest.setIscsatscorechanged(isCsatScoreChanged || false);
    Object.keys(data.Variables).forEach((value) => {
      if (value && data.Variables[value]) {
        variableMap.set(value, `${data.Variables[value]}`);
      }
    });
    const Feedbackgateway = new UserFeedback(Gateway);

    const response = await Feedbackgateway.UpsertCSAT(upsertCSATFormRequest);
    return response;
  } catch (error) {
    return catchError(error);
  }
};

const getIdentity = () => {
  const identity = localStorage.getItem(IDENTITY_KEY);
  if (!identity) {
    localStorage.setItem(IDENTITY_KEY, uuid());
  }
  return localStorage.getItem(IDENTITY_KEY) || '';
};

export const trackEvent = async (
  module,
  event,
  clientId,
  visitorToken,
  component,
  data
) => {
  const identity = getIdentity();
  if (!data) {
    data = {};
  }
  data = {
    ...data,
    ui_version: 'v1',
    module,
    component,
    event,
  };
  const events = `${component ? `${component}:` : ''}${event}`;
  const { referrer } = document;
  const currentUrl = window.location.href;
  const { vendor } = window.navigator;

  try {
    getGatewayOptions(visitorToken, clientId);
    const trackEventRequest = new TrackEventRequest();
    const dataMap = trackEventRequest.getDataMap();
    const screen = new Screen();
    if (window.screen) {
      screen.setWidth(window.screen.width);
      screen.setHeight(window.screen.height);
    }
    trackEventRequest.setCategory(module);
    trackEventRequest.setIdentity(`endUser:${identity}`);
    trackEventRequest.setReferrer(referrer);
    trackEventRequest.setEvent(events);
    trackEventRequest.setCurrenturl(currentUrl);
    trackEventRequest.setVendor(vendor);
    trackEventRequest.setScreen(screen);

    Object.keys(data).forEach((value) => {
      if (value && data[value]) {
        dataMap.set(value, `${data[value]}`);
      }
    });
    const GatewayAnalyticsGateway = new GatewayAnalytics(Gateway);
    const response = await GatewayAnalyticsGateway.TrackEvent(
      trackEventRequest
    );
    return response;
  } catch (error) {
    return catchError(error);
  }
};

import { useEffect, useState } from 'preact/hooks';
import { trackEvent } from '../../../api';
import {
  COMPONENT_CSAT_FORM,
  CUSTOMER_WIDGET_EVENT_MODULE,
} from '../../constants';
import { getPosition } from '../../helper';
import './style.css';

const ButtonField = ({
  fieldData,
  csatForm,
  setCSATForm,
  required,
  clientId,
  vt,
  upsertCsatForm,
}) => {
  const [selected, setSelected] = useState([]);
  const [isRequired, setIsRequired] = useState(false);

  const multiSelectHandler = (selectedButton) => {
    const index = selected.findIndex((btn) => btn === selectedButton);
    if (index !== -1) {
      selected.splice(index, 1);
      setSelected([...selected]);
    } else {
      setSelected([...selected, selectedButton]);
    }
  };

  const singleSelectHandler = (selectedButton) => {
    setSelected([selectedButton]);
  };

  const handleButtonClick = (e) => {
    const selectedButton = e.target.value;
    if (fieldData.ButtonsField) {
      if (fieldData.ButtonsField.SingleSelect) {
        singleSelectHandler(selectedButton);
      } else {
        multiSelectHandler(selectedButton);
      }
    }
    const position = getPosition(csatForm, fieldData.Id);
    trackEvent(
      CUSTOMER_WIDGET_EVENT_MODULE,
      'ButtonsClicked',
      clientId,
      vt,
      COMPONENT_CSAT_FORM,
      {
        formName: csatForm.Name,
        label: fieldData.ButtonsField.Title,
        labelPosition: position,
        isMandatory: fieldData.IsMandatory,
        buttonName: selectedButton,
        buttonClicked: true,
      }
    );
  };

  useEffect(() => {
    csatForm.FormFields.map((field) => {
      if (field.Id === fieldData.Id) {
        fieldData['value'] = selected;
      }
      return csatForm;
    });
    setCSATForm(csatForm);
  }, [selected]);

  const isSelected = (button) => {
    return selected.includes(button);
  };

  useEffect(() => {
    setIsRequired(required.hasOwnProperty(fieldData.Id));
  }, [required]);

  const handleOnBlur = (e) => {
    upsertCsatForm();
  };

  return (
    <div className="buttonContainer">
      <div className="title">
        {fieldData.ButtonsField.Title}
        {isRequired && <span className="required"> * required</span>}
        {!fieldData.IsMandatory ? <span> (optional)</span> : ''}
      </div>
      <div className="buttonWrapper" id={fieldData.ButtonsField.Id}>
        {fieldData.ButtonsField.Buttons.map((button) => {
          return (
            <button
              type="button"
              className={`buttonField ${
                isSelected(button) ? 'activeButton' : ''
              }`}
              onClick={handleButtonClick}
              onBlur={handleOnBlur}
              value={button}
            >
              {button}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ButtonField;

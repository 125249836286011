import './style.css';
import { useEffect, useState } from 'preact/hooks';
import {
  COMPONENT_CSAT_FORM,
  CUSTOMER_WIDGET_EVENT_MODULE,
  RATING_TYPE,
} from '../../constants';
import StarRating from './Components/StarRating';
import NumberRating from './Components/NumberRating';
import ThumbRating from './Components/ThumbRating';
import EmojiRating from './Components/EmojiRating';
import { trackEvent } from '../../../api';
import { getPosition } from '../../helper';

const RatingField = ({
  fieldData,
  csatForm,
  setCSATForm,
  required,
  clientId,
  vt,
  upsertCsatForm,
}) => {
  const [rating, setRating] = useState(0);
  const [ratingComment, setRatingComment] = useState('');
  const [isRequired, setIsRequired] = useState(false);
  const [isFollowUpRequired, setIsFollowUpRequired] = useState(false);

  const handleRating = (rate, fieldId) => {
    if (fieldId === fieldData.Id) {
      setRating(rate);
    }
  };

  const callTrackAndUpsert = (rate) => {
    const position = getPosition(csatForm, fieldData.Id);
    trackEvent(
      CUSTOMER_WIDGET_EVENT_MODULE,
      'RatingClicked',
      clientId,
      vt,
      COMPONENT_CSAT_FORM,
      {
        formName: csatForm.Name,
        label: fieldData.RatingField.Title,
        labelPosition: position,
        ratingType: fieldData.RatingField.Type,
        isMandatory: fieldData.IsMandatory,
        usedForCSATCalculation: fieldData.RatingField.IsPrimaryScore,
        value: rate,
      }
    );
    upsertCsatForm(fieldData.RatingField.IsPrimaryScore);
  };

  useEffect(() => {
    csatForm.FormFields.map((field) => {
      if (field.Id === fieldData.Id) {
        fieldData['value'] = rating;
      }
      return csatForm;
    });
    setCSATForm(csatForm);
    if (rating) callTrackAndUpsert(rating);
  }, [rating]);

  useEffect(() => {
    csatForm.FormFields.map((field) => {
      if (field.Id === fieldData.Id) {
        fieldData['comment'] = ratingComment;
      }
      return csatForm;
    });
    setCSATForm(csatForm);
  }, [ratingComment]);

  useEffect(() => {
    setIsRequired(required.hasOwnProperty(fieldData.Id));
    setIsFollowUpRequired(required.hasOwnProperty(`${fieldData.Id}followup`));
  }, [required]);

  const handleOnFocus = (e, ques) => {
    const position = getPosition(csatForm, fieldData.Id);
    trackEvent(
      CUSTOMER_WIDGET_EVENT_MODULE,
      "FollowUpQuestionClicked",
      clientId,
      vt,
      COMPONENT_CSAT_FORM,
      {
        formName: csatForm.Name,
        label: ques.Title,
        labelType: "FollowUpLabel",
        labelPosition: `${position}.1`,
        isMandatory: fieldData.RatingField.IsFollowUpMandatory,
        usedForCSATComment: fieldData.RatingField.UseFollowUpForComments,
      }
    );
  };

  const handleOnChange = (e, ques) => {
    const val = e.target.value;
    setRatingComment(e.target.value);
    if (val && val.length === 1) {
      const position = getPosition(csatForm, fieldData.Id);
      trackEvent(
        CUSTOMER_WIDGET_EVENT_MODULE,
        "FollowUpQuestionClicked",
        clientId,
        vt,
        COMPONENT_CSAT_FORM,
        {
          formName: csatForm.Name,
          label: ques.Title,
          labelType: "FollowUpLabel",
          labelPosition: `${position}.1`,
          isMandatory: fieldData.RatingField.IsFollowUpMandatory,
          usedForCSATComment: fieldData.RatingField.UseFollowUpForComments,
          textAdded: true,
        }
      );
    }
  };

  const handleOnBlur = (e) => {
    const val = e.target.value;
    if (val && val.trim().length > 0) {
      upsertCsatForm();
    }
  };

  return (
    <div>
      <div className="ratingWrapper">
        <div className="title">
          {fieldData.RatingField.Title}
          {isRequired && <span className="required"> * required</span>}
          {!fieldData.IsMandatory ? <span> (optional)</span> : ''}
        </div>
        <div className="ratingField">
          {fieldData.RatingField.Type === RATING_TYPE.STAR && (
            <StarRating
              id={fieldData.Id}
              ratingScore={rating}
              setRatingScore={handleRating}
            />
          )}
          {fieldData.RatingField.Type === RATING_TYPE.NUMBER && (
            <NumberRating
              id={fieldData.Id}
              ratingScore={rating}
              setRatingScore={handleRating}
            />
          )}
          {fieldData.RatingField.Type === RATING_TYPE.EMOJI && (
            <EmojiRating
              id={fieldData.Id}
              ratingScore={rating}
              setRatingScore={handleRating}
            />
          )}
          {fieldData.RatingField.Type === RATING_TYPE.THUMB && (
            <ThumbRating
              id={fieldData.Id}
              ratingScore={rating}
              setRatingScore={handleRating}
            />
          )}
        </div>
      </div>
      {fieldData.RatingField.Question.map((ques) => {
        if (rating === ques.Score)
          return (
            <div className="followUp">
              <div className="title">
                {ques.Title}
                {isFollowUpRequired && (
                  <span className="required"> * required</span>
                )}
                {fieldData.RatingField.IsFollowUpMandatory &&
                rating <= fieldData.RatingField.MinScoreForFollowUp ? (
                  ''
                ) : (
                  <span> (optional)</span>
                )}
              </div>
              <textarea
                id={fieldData.Id}
                rows={4}
                className="textarea"
                placeholder={ques.Placeholder}
                value={ratingComment}
                onChange={(e) => handleOnChange(e, ques)}
                onFocus={(e) => handleOnFocus(e, ques)}
                onBlur={(e) => handleOnBlur(e)}
              />
            </div>
          );
        return null;
      })}
    </div>
  );
};

export default RatingField;

export const RATING_TYPE = {
  UNKNOWN: 'FIELD_TYPE_UNKNOWN',
  STAR: 'FIELD_TYPE_STAR',
  NUMBER: 'FIELD_TYPE_NUMBER',
  EMOJI: 'FIELD_TYPE_EMOJI',
  THUMB: 'FIELD_TYPE_THUMBS',
};

export const RATING_DESC = ['Worst!', 'Bad!', 'Average!', 'Good!', 'Best!'];

export const ERROR_MSG = 'Something went wrong';

export const CUSTOMER_WIDGET_EVENT_MODULE = 'CustomerWidget';
export const COMPONENT_CSAT_FORM = 'CsatForm';

import { useEffect, useState } from 'preact/hooks';
import { trackEvent } from '../../../api';
import { COMPONENT_CSAT_FORM, CUSTOMER_WIDGET_EVENT_MODULE } from '../../constants';
import { getPosition } from '../../helper';
import './style.css';

const TextField = ({ fieldData, csatForm, setCSATForm, required, clientId, vt, upsertCsatForm }) => {
  const [textValue, setTextValue] = useState('');
  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    csatForm.FormFields.map((field) => {
      if (field.Id === fieldData.Id) {
        fieldData['value'] = textValue;
      }
      return csatForm;
    });
    setCSATForm(csatForm);
  }, [textValue]);

  useEffect(() => {
    setIsRequired(required.hasOwnProperty(fieldData.Id));
  }, [required]);

  const handleOnFocus = () => {
    const position = getPosition(csatForm, fieldData.Id);
    trackEvent(
      CUSTOMER_WIDGET_EVENT_MODULE,
      "TextFieldClicked",
      clientId,
      vt,
      COMPONENT_CSAT_FORM,
      {
        formName: csatForm.Name,
        label: fieldData.FreeTextField.Title,
        labelType: "TextLabel",
        labelPosition: position,
        isMandatory: fieldData.IsMandatory,
        usedForCSATComment: false,
      }
    );
  }

  const handleChange = (e) => {
    const val = e.target.value;
    setTextValue(val);
    if (val && val.length === 1) {
      const position = getPosition(csatForm, fieldData.Id);
      trackEvent(
        CUSTOMER_WIDGET_EVENT_MODULE,
        "TextFieldClicked",
        clientId,
        vt,
        COMPONENT_CSAT_FORM,
        {
          formName: csatForm.Name,
          label: fieldData.FreeTextField.Title,
          labelType: "TextLabel",
          labelPosition: position,
          isMandatory: fieldData.IsMandatory,
          usedForCSATComment: false,
          textAdded: true,
        }
      );
    }
  };

  const handleOnBlur = (e) => {
    const val = e.target.value;
    if (val && val.trim().length > 0) {
      upsertCsatForm();
    }
  };

  return (
    <div className="textWrapper">
      <div className="title">
        {fieldData.FreeTextField.Title}
        {isRequired && <span className="required"> * required</span>}
        {!fieldData.IsMandatory ? <span> (optional)</span> : ''}
      </div>
      <textarea
        id={fieldData.FreeTextField.Id}
        rows={4}
        className="textarea"
        placeholder={fieldData.FreeTextField.Placeholder}
        maxLength={
          fieldData.FreeTextField.MaxCharacters === '0'
            ? undefined
            : fieldData.FreeTextField.MaxCharacters
        }
        value={textValue}
        onChange={handleChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
    </div>
  );
};

export default TextField;
